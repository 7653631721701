@import "../../styles/helpers";

$component: opbrengsten-diagnose;

.#{$component} {

  &__proceed-type-buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__proceed-type-button {
    border: 1px solid var(--c-black);

    & + .#{$component}__proceed-type-button {
      border-top: 0;
      margin: 0;
    }
  }
}
