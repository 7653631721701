@import "../../styles/helpers";

$component: 'radio-input';

.#{$component} {

  & + & {
    margin-top: .625rem;
  }

  &__input {
    position: absolute;
    cursor: default;
    opacity: 0;

    &:checked ~ .#{$component}__label-container {
      border-color: var(--c-primary);
      background-color: var(--c-primary-light);

      .#{$component}__indication-box::before {
        content: "";
      }
    }

    &:focus ~ .#{$component}__label-container {
      border-color: var(--c-secondary);
      background-color: var(--c-secondary-light);

      .#{$component}__indication-box {
        color: var(--c-secondary);
      }
    }

    &:checked:disabled ~ .#{$component}__label-container {
      color: var(--c-gray-dark);
      border-color: var(--c-primary);
      background-color: var(--c-primary-light);

      .#{$component}__indication-box {
        border-color: var(--c-primary);
      }
    }

    &:disabled ~ .#{$component}__label-container {
      cursor: default;
      color: var(--c-gray);
      border-color: var(--c-gray);
      background-color: var(--c-gray-light);
      opacity: .7;

      .#{$component}__indication-box {
        border-color: var(--c-gray);
      }
    }
  }

  &__label-container {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 1.125rem 1.875rem;
    cursor: pointer;
    border: 2px solid var(--c-gray-light);

    &:hover {
      background: var(--c-primary-very-light);
    }
  }

  &__label {
    font-size: 1rem;
    line-height: 1.4;
  }

  &__indication-box {
    @extend %indication-box;
  }
}
