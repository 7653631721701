@import "../../styles/helpers";

.aan-de-slag-actieplan {

  &__download-button {
    width: fit-content;
  }

  &__actieplan-preview {
    width: 100%;
    max-width: 800px;
  }
}
