@import "../../styles/helpers";

.quiz-question {
  max-width: 40rem;

  &__chapeau {
    margin-bottom: 1.25rem;
    padding: 0;
    font-style: italic;
  }

  &__label {
    display: block;
    font-weight: 400;
    line-height: 1.5;
    color: var(--c-black);

    & + * {
      margin-top: .625rem;
    }
  }

  &__submit-button {
    margin-top: 1.25rem;
    padding: 1rem 2rem;
    font-size: 1rem;
    color: var(--c-white);
    cursor: pointer;
    border: none;
    background-color: var(--c-primary);

    &:disabled {
      color: var(--c-gray);
      cursor: not-allowed;
      background-color: var(--c-gray-light);
    }
  }

  &__audio-player {
    margin: 1rem 0;
  }

  &__error {
    font-weight: 400;
    font-style: italic;
    color: var(--c-negative);
  }
}
