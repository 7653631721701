@import "../../styles/helpers";

.duration-estimation {
  display: flex;
  align-items: center;
  border-radius: 1.25rem;
  width: fit-content;
  height: 1.75rem;
  padding: 0 0.75rem;
  white-space: nowrap;
  background: var(--c-gray-light);

  &__icon {
    margin: 0;
    width: 1rem;
    height: 1rem;
    fill: var(--c-gray-dark);
  }

  &__label {
    display: block;
    margin-right: .5rem;
    font-size: 0.875rem;
  }
}
