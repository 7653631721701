@import "../../styles/helpers";

.answer-bubble {
  position: relative;
  margin-top: 1.25rem;
  padding: 1.875rem;
  font-weight: 400;
  background-color: var(--c-primary-light);

  &::before {
    content: "";
    position: absolute;
    top: -.625rem;
    left: 1.875rem;
    z-index: 10;
    width: 1.25rem;
    height: 1.25rem;
    transform: rotate(45deg) skew(-10deg, -10deg);
    background-color: inherit;
  }

  * {
    margin: 0;

    & + * {
      margin-top: 1rem;
    }
  }

  &__image-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 0 !important;

    li {
      list-style-type: none;

      & + li {
        margin-top: 1rem;
      }
    }
  }

  &__image {
    width: 100%;
  }
}
