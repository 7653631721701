@import "../../styles/helpers";

.progress-bar {
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;
    font-size: 0.875rem;
  }

  &__bar {
    position: relative;
    border-radius: 0.5rem;
    width: 100%;
    height: 0.5rem;
    background: var(--c-white);
    overflow: hidden;
  }

  &__bar-inner {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    width: 50%;
    height: 100%;
    background: var(--c-primary);
  }
}
