@import "../../styles/helpers";

.total-progression-text {
  font-weight: 500;

  &__progress {
    margin: 0 0.25rem;
    color: var(--c-primary);
  }
}
