@import "../../styles/helpers";

.dashboard {

  &__total-progression-text {
    margin-top: 1rem;
  }

  &__module-status-cards-container {
    margin-top: 4rem;
  }
}
