@import "../../styles/helpers";

.binary-choice {

  & + & {
    margin-top: 1.25rem;
  }

  &__title {
    padding: 0;
    font-style: italic;
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    margin-top: 1.25rem;

    @include for-size(tablet-landscape-up) {
      flex-direction: row;
    }
  }

  &__input {
    width: 100%;

    @include for-size(tablet-landscape-up) {
      width: 50%;
    }

    & + & {

      @include for-size(tablet-landscape-up) {
        margin-top: 0;
        margin-left: 1.25rem;
      }
    }
  }
}
