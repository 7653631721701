@import "../../styles/helpers";

.logo {

  circle,
  path,
  polygon,
  rect {
    fill: currentColor;
  }

  &__text {
    fill: #1d1d1b;
  }

  &__griffin {
    fill: #0080c9;
  }
}
