%indication-box {
  position: relative;
  min-width: 1.25rem;
  max-width: 1.25rem;
  min-height: 1.25rem;
  max-height: 1.25rem;
  margin-right: 1.25rem;
  color: var(--c-primary);
  border-radius: 1.25rem;
  border: 2px solid currentColor;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: .75rem;
    max-width: .75rem;
    min-height: .75rem;
    max-height: .75rem;
    border-radius: .75rem;
    background-color: currentColor;
    transform: translate(-50%, -50%);
  }
}
