// Sizes
$top-bar-height: 5rem;
$side-navigation-width: 22rem;
$side-navigation-width-small: calc(#{$side-navigation-width} * .7);

// Typography
$f-primary: 'Helvetica Neue', 'Helvetica', system-ui, sans-serif;
$f-secondary: 'Roboto Condensed', 'Roboto', sans-serif;

// Colours
$c-primary:             #007ecc;
$c-primary-light:       #ddf2ff;
$c-primary-very-light:  #f7fcff;
$c-primary-dark:        #005f99;
$c-secondary:           #0018cc;
$c-secondary-light:     #dde1ff;


$c-negative:            #cc0018;

$c-white:               #ffffff;
$c-black:               #000000;

$c-gray:                #b8bdbe;
$c-gray-light:          #ebeded;
$c-gray-dark:           #393d3e;

// Shadow
$c-box-shadow: rgba(226, 226, 226, 0.2);

// Other
$focus-ring: 0 0 0 3px var(--c-white), 0 0 0 5px var(--c-primary);

:root {
  --c-primary:            #{$c-primary};
  --c-primary-light:      #{$c-primary-light};
  --c-primary-very-light: #{$c-primary-very-light};
  --c-primary-dark:       #{$c-primary-dark};
  --c-secondary:          #{$c-secondary};
  --c-secondary-light:    #{$c-secondary-light};
  --c-negative:           #{$c-negative};
  --c-white:              #{$c-white};
  --c-black:              #{$c-black};
  --c-gray:               #{$c-gray};
  --c-gray-light:         #{$c-gray-light};
  --c-gray-dark:          #{$c-gray-dark};

  --box-shadow:           0px 4px 8px #{$c-box-shadow};
}
