@import "../../styles/helpers";

.top-bar {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: $top-bar-height;
  padding: 1.5rem 1.875rem;
  color: var(--c-white);
  background-color: var(--c-primary);

  &__logo {
    width: 5rem;
    max-height: 2rem;
  }

  &__app-title {
    display: block;
    margin-left: 1.875rem;
    font-family: $f-secondary;
    font-size: 1.375rem;
  }

  &__username-container {
    display: flex;
    margin-left: auto;
  }

  &__username {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1rem;
  }

  &__logout-button {
    margin-left: .75rem;
    padding: 1.5rem;
    padding-left: .75rem;
    color: var(--c-white);
    cursor: pointer;
    border: none;
    background: none;
  }

  &__logout-icon {
    height: 1.125rem;
  }
}
