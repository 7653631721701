@import "../../styles/helpers";

.start-overview-tool {

  &__page-three-intro {
    display: block;
    margin-bottom: 3rem;
  }

  &__overview-image {
    margin-bottom: 2rem;
    width: 100%;
    max-width: 80rem;

    @include for-size(tablet-landscape-up) {
      width: calc(100vw - #{$side-navigation-width});
    }

    @include for-size(desktop-up) {
      width: calc(100vw - #{$side-navigation-width} - 6rem);
    }
  }
}
