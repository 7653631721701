@import "../../../../styles/helpers";

.justified-score-button {
  display: flex;
  border: 0;
  border-radius: 2px;
  margin-top: 0;
  width: 100%;
  max-width: 3.75rem;
  height: 1rem;
  pointer-events: none;

  & + & {
    margin-left: 0.25rem;
  }

  &--is-interactive {
    pointer-events: visible;
    cursor: pointer;
  }

  &--is-active, &:hover {
    background: var(--c-primary);
  }

  &--is-active:hover {
    background: var(--c-primary-dark);
  }
}