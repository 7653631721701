@import "../../styles/helpers";

.proceed-type-button {
  color: var(--c-black);
  background-color: var(--c-primary-light);

  &:hover {
    color: var(--c-white);
  }

  &--is-active {
    color: var(--c-white);
    background-color: var(--c-primary);
  }

  & + & {
    margin-top: 1rem;
  }
}
