@import "../../styles/helpers";

.learning-goals {

  &__list {
    list-style: none;
    padding: 0;
  }

  &__list-item {
    line-height: 1.5;
    padding-left: 2rem;
    background-position: 0 0.25rem;
    background-size: 1rem;
    background-repeat: no-repeat;


    &--is-knowledge {
      background-image: url('/images/onderzoek.svg');
    }

    &--is-insight {
      background-image: url('/images/inzicht.svg');
    }

    &--is-skill {
      background-image: url('/images/vaardigheid.svg');
    }
  }
}
