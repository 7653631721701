@import "../../styles/helpers";

.page-header {
  margin-bottom: 1.25rem;

  &__title {
    margin: 0;
    font-size: 1.375rem;
    text-transform: uppercase;
  }
}
