@import "../../styles/helpers";

.bottom-navigation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  margin-top: auto;
  margin-bottom: 1.625rem;
  padding-top: 1.625rem;

  @include for-size(tablet-landscape-up) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__direction-button-container {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;

    @include for-size(tablet-landscape-up) {
      flex-direction: row-reverse;
      margin-top: 0;
      margin-left: auto;
    }
  }

  &__direction-button {
    min-height: 3.125rem;

    &--prev {
      background-color: var(--c-gray);

      &:hover,
      &:focus {
        background-color: var(--c-gray-dark);
      }
    }

    &--next {
      order: 1;
      margin-left: .5rem;

      @include for-size(tablet-landscape-up) {
        order: -1;
      }
    }
  }
}
