@import "../../styles/helpers";

.table-container {
  width: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-spacing: 0;

  & + & {
    margin-top: 2rem;
  }

  thead {
    color: var(--c-white);
    background-color: var(--c-primary);
  }

  th {
    padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    border: none;
  }

  tbody td {
    border-bottom: 2px solid var(--c-gray);

    &:first-child {
      border-left: 2px solid var(--c-gray);
    }

    &:last-child {
      border-right: 2px solid var(--c-gray);
    }
  }

  td {
    padding: 1rem 2rem;
    word-break: break-word;
  }
}
