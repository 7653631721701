@import "../../styles/helpers";

.icon {

  circle,
  path,
  polygon,
  rect {
    fill: currentColor;
  }

  .stroke {
    fill: none;
    stroke: currentColor;
  }
}

.second-hand {
  transform-origin: center;
  animation: second-hand 60s linear infinite;
}
