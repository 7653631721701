@import "../../styles/helpers";

.side-navigation {
  position: fixed;
  top: $top-bar-height;
  left: 0;
  width: $side-navigation-width-small;
  height: calc(100vh - #{$top-bar-height});
  padding: 1.875rem;
  overflow-x: hidden;
  overflow-y: auto;
  color: var(--c-white);
  background-color: var(--c-gray-dark);

  @include for-size(desktop-up) {
    width: $side-navigation-width;
  }

  &__module-container {
    margin: 0;

    & + & {
      margin-top: 1.875rem;
    }
  }

  &__module-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    color: var(--c-white);
    cursor: pointer;
    border: none;
    background: none;

    &--is-inactive {
      appearance: none;
      cursor: default;
      opacity: .5;

      &:focus {
        outline: none;
      }
    }
  }

  &__module-button-title {
    display: block;
    font-family: $f-secondary;
    font-size: 1.375rem;
    white-space: nowrap;
  }

  &__module-button-icon {
    width: 1rem;
    height: 1rem;
    color: currentColor;
  }

  &__module-nav {
    display: none;
    margin-top: 1.25rem;

    &--is-collapsed {
      display: block;
    }
  }

  &__module-nav-list {
    margin: 0;
    padding: 0;
  }

  &__module-nav-item {
    position: relative;
    margin: 0;
    padding-left: 2rem;
    list-style-type: none;

    &--static-module {
      padding-left: 1rem;
    }

    & + & {
      margin-top: .875rem;
    }
  }

  &__module-nav-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    width: 1.375rem;
    height: 1.375rem;
    border-radius: 1.375rem;
    background-color: var(--c-primary);
    transform: translateY(-50%);
  }

  &__module-nav-icon {
    width: .75rem;

    &--active-page {
      width: .5rem;
    }
  }

  &__module-nav-link {
    font-family: $f-secondary;
    font-size: 1.125rem;
    color: var(--c-white);
    text-decoration: none;
    opacity: .5;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &--is-active {
      opacity: 1;
    }

    &--disabled {
      opacity: .25;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  &__link-dashboard {
    margin-bottom: 2rem;
    padding: 0;
    text-decoration: none;
    font-size: .875rem;
    color: var(--c-white);
    background: none;

    &:focus, &:hover {
      background: none;
      text-decoration: underline;
    }
  }

  &__link-dashboard-icon {
    margin-right: 1rem;
  }
}
