@import "../../../../styles/helpers";

.reflection-item {
  line-height: 1.5;
  padding-left: 1rem;
  background-position: 0 0.25rem;
  background-size: 1rem;
  background-repeat: no-repeat;

  &--has-icon {
    padding-left: 2rem;
  }

  &--is-insight {
    background-image: url('/images/inzicht.svg');
  }

  &--is-strength {
    background-image: url('/images/voordeel.svg');
  }

  &--is-danger {
    background-image: url('/images/gevaar.svg');
  }

  &--is-solution {
    background-image: url('/images/connectie.svg');
  }
}
