@import "../../styles/helpers";

$component: 'pagination';

.#{$component} {
  display: flex;
  align-items: center;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    color: var(--c-primary);
  }

  &__input {
    position: absolute;
    pointer-events: none;
    opacity: 0;

    &:hover ~ .#{$component}__button {
      background-color: var(--c-primary-light);
    }

    &:checked {

      & ~ .#{$component}__button {
        z-index: 10;
        color: var(--c-white);
        background-color: var(--c-primary);
      }

      &:hover ~ .#{$component}__button ,
      &:focus ~ .#{$component}__button {
        box-shadow: $focus-ring;
      }
    }

    &:disabled {

      & ~ .#{$component}__button {
        color: var(--c-gray);
        cursor: not-allowed;
        background-color: var(--c-gray-light);
      }
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    font-weight: 400;
    color: currentColor;
    cursor: pointer;
    border: none;
    background: none;

    &:hover,
    &:focus {
      background-color: var(--c-primary-light);
    }
  }
}
