@import "../../styles/helpers";

.input {
  position: relative;
  display: block;

  & + & {
    margin-top: 1.25rem;
  }

  &__label {
    display: block;
    margin-right: .625rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--c-black);
  }

  &__password-container {
    display: flex;
    align-items: flex-end;
    height: 2.5rem;
    margin-top: .625rem;
  }

  &__field {
    width: 100%;
    margin-top: .625rem;
    padding: .625rem 1.25rem;
    font-family: $f-primary;
    font-size: 1rem;
    color: var(--c-black);
    border: 1px solid var(--c-gray);
    background-color: var(--c-white);

    &:disabled {
      cursor: not-allowed;
      opacity: .7;
    }

    &--is-password {
      margin-top: 0;
      border-right: none;
    }
  }

  &__visibility-button {
    display: flex;
    align-items: center;
    height: 100%;
    z-index: 10;
    cursor: pointer;
    border: 1px solid var(--c-gray);
    border-left: none;
    background: none;
  }

  &__visibility-icon {
    width: 1rem;
    color: var(--c-gray-dark);
  }
}
