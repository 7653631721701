@import "../../styles/helpers";

.module-status-cards-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__module-status-card {
    margin-bottom: 1.5rem;
    width: calc(50% - 0.75rem);

    @include for-size(tablet-landscape-up) {
      width: calc(#{100% / 3} - 0.75rem);
    }
  }
}
