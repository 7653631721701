@import "../../styles/helpers";

$component: 'accordion';

.#{$component} {
  margin-top: 2rem;

  .#{$component}__list {
    margin: 0;
    padding: 0;
  }

  &__item {
    list-style-type: none;
  }

  &__checkbox {
    position: absolute;
    opacity: 0;

    &:hover ~ .#{$component}__label,
    &:focus ~ .#{$component}__label,
    &:checked ~ .#{$component}__label {
      color: var(--c-white);
      background-color: var(--c-primary);
    }

    &:checked ~ .#{$component}__content {
      display: block
    }
  }

  &__label {
    display: block;
    padding: 1rem 2.25rem;
    font-weight: 600;
    color: var(--c-gray-dark);
    cursor: pointer;
    border-bottom: 1px solid var(--c-gray);
    background-color: var(--c-primary-light);
  }

  &__content {
    display: none;
    padding: 2.25rem;
    border-bottom: 1px solid var(--c-gray);
    background-color: var(--c-primary-very-light);

    h3 {
      color: var(--c-primary);
    }

    * {
      margin: 0;

      & + * {
        margin-top: 1rem;
      }
    }

    a {
      word-break: break-word;
    }
  }

  .#{$component} {
    .#{$component}__label {
      border: 0;
      padding: 0.5rem 1.25rem;
      font-weight: 500;
      color: var(--c-black);
      background-color: var(--c-primary-light);

      &:hover {
        color: var(--c-white);
        background-color: var(--c-primary);
      }
    }

    .#{$component}__checkbox:checked + .#{$component}__label {
      color: var(--c-white);
      background-color: var(--c-primary);
    }

    .#{$component}__content {
      border: 1px solid var(--c-gray-light);
      padding: 1.5rem;
      margin-top: 0;
      background-color: var(--c-primary-light);
    }
  }
}
