@import "../../styles/helpers";

$case-selection-gutter: 1.25rem;

.case-selection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  margin-top: 2rem;
  padding: 0;

  &__item {
    width: calc(50% - (#{$case-selection-gutter} / 2));
    margin-top: $case-selection-gutter;
    list-style-type: none;

    &:first-child,
    &:nth-child(2) {
      margin-top: 0;
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 1.25rem;
    cursor: pointer;
    border-radius: 2px;
    border: 2px solid var(--c-gray);
    background-color: var(--c-white);

    &:hover,
    &:focus {
      border-color: var(--c-primary);
      background-color: var(--c-primary-light);
    }
  }

  &__title {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
  }

  &__intro {
    margin: 0;
    margin-top: .625rem;
    font-size: 1rem;
    font-weight: 300;
  }
}
