@import "../../styles/helpers";

.button {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  font-size: 1rem;
  color: var(--c-white);
  cursor: pointer;
  border: none;
  background-color: var(--c-primary);

  &:hover,
  &:focus {
    background-color: var(--c-primary-dark);
  }

  &:disabled, &--is-disabled {
    color: var(--c-gray);
    cursor: not-allowed;
    background-color: var(--c-gray-light);
  }

  &__icon {
    width: .5rem;
    fill: currentColor;
    max-height: 2rem;

    &--left {
      margin-right: .625rem;
    }

    &--right {
      margin-left: .625rem;
    }
  }
}

a.button {
  &--is-disabled {
    pointer-events: none;
  }
}