@import "../../styles/helpers";

.html-content {
  width: 100%;
  max-width: 50rem;

  & > div + div {
    margin-top: 1.625rem;
  }

  ul {
    padding-left: 1.1rem;
  }

  li {
    line-height: 1.5;
  }
}
