@import "../../../../styles/helpers";

.drijfveren-casuistiek-case {

  &__image {
    margin-bottom: 1rem;
    width: 100%;
    max-width: 30rem;
  }
}
