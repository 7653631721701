@import "../../styles/helpers";

.textarea {

  &__label {
    display: block;
    margin-right: .625rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--c-black);
  }

  &__field {
    width: 100%;
    min-height: 15rem;
    margin-top: .625rem;
    padding: 1.5rem 1.25rem;
    resize: vertical;
    font-family: $f-primary;
    font-size: 1rem;
    color: var(--c-black);
    border: 1px solid var(--c-gray);
    background-color: var(--c-white);

    &:disabled {
      cursor: not-allowed;
      opacity: .7;
    }
  }
}
