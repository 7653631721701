@import "../../styles/helpers";

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .75);

  &__close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.5rem;
    color: var(--c-white);
    cursor: pointer;
    border: none;
    background: none;
  }

  &__close-icon {
    height: 2rem;
  }

  &__content {
    position: relative;
    z-index: 10;
    width: 75%;
    max-width: 50rem;
    height: 85%;
    max-height: 55rem;
    overflow: auto;
    padding: 1.5rem;
    background-color: var(--c-white);
  }

  &__backdrop-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: transparent;
    cursor: pointer;
    background: none;
    border: none;
  }
}
