@import "../../styles/helpers";

.module-estimation {
  display: flex;
  align-items: center;

  &__duration {
    margin-left: 0.5rem;
  }

  & + & {
    margin-top: 1rem;
  }
}
