@import "../../styles/helpers";

.ordered-selection {

  &__label {
    display: block;
    margin-right: .625rem;
    margin-bottom: .625rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--c-black);
  }

  &__max-feedback {
    font-style: italic;
    color: var(--c-negative);
  }
}
