@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap');
@import "helpers";

* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: $f-primary;
  font-size: 1rem;
  color: var(--c-gray-dark);
  background-color: var(--c-white);
}

.content-container {
  display: flex;
  height: calc(100vh - #{$top-bar-height})
}

.system-patching {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  padding: 1rem;
  text-align: center;
  line-height: 1.4;
  color: var(--c-white);
  border-radius: .25rem;
  background-color: rgba(0, 0, 0, .75);
  transform: translate(-50%, -50%);
}

.page-container {
  width: 100vw;
  height: 100vh;
  padding: 1.625rem 3.25rem;
  padding-top: calc(#{$top-bar-height} + 3.125rem);

  &--has-side-navigation {
    padding-left: calc(#{$side-navigation-width-small} + 3.25rem);

    @include for-size(desktop-up) {
      padding-left: calc(#{$side-navigation-width} + 3.25rem);
    }
  }
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__content {
    width: 100%;
    max-width: 50rem;

    & > div + div {
      margin-top: 1.625rem;
    }

    ul {
      padding-left: 1.1rem;
    }

    li {
      line-height: 1.5;
    }
  }

  &__block {

    & + * {
      margin-top: 1.625rem;
    }
  }

  .modal-button {
    margin: 0;
    padding: 0;
    font: inherit;
    color: $c-primary;
    cursor: pointer;
    border: none;
    background: none;
  }
}

h1, h2, h3 {
  font-family: $f-primary;
  font-weight: 600;
}

h1 {
  font-family: $f-secondary;
  font-size: 2.625rem;
  font-weight: 400;
  color: var(--c-primary);
}

h2 {
  font-size: 1.125rem;
}

h3 {
  font-size: 1rem;
}

p {
  line-height: 1.5;
}

p a {
  text-decoration: none;
  color: var(--c-primary);

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

blockquote {
  margin: 0;
  font-style: italic;
}

iframe {
  width: 100%;
  max-width: 44rem;
  height: 24rem;
  border: none;
}

.flex-list {
  display: flex;
  flex-direction: column;
  padding: 0;

  @include for-size(tablet-landscape-up) {
    flex-direction: row;
  }

  & > li {
    list-style-type: none;

    & + li {
      margin-top: 1rem;

      @include for-size(tablet-landscape-up) {
        margin-top: 0;
        margin-left: 1rem;
      }
    }
  }
}

.info {

  & + & {
    margin-top: 1.5rem;
  }

  h4 {
    margin: 0;
    color: var(--c-primary);
  }

  p {
    margin: 0;
    margin-top: .75rem;
  }
}

.table-card-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0 !important;

  &__item {
    width: 95%;
    max-width: 32rem;
    margin-top: 1rem;
    padding: 1rem;
    list-style-type: none;
    border: 2px solid var(--c-gray-light);
    background-color: var(--c-white);

    &:nth-child(odd) {
      background-color: var(--c-gray-light);
    }

    p {
      margin-top: .25rem;
      margin-bottom: 1.75rem;
    }
  }

  &__item-list {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0 !important;

    li {

      & + li {
        margin-left: 1.5rem;
      }
    }
  }

  &__title {
    margin: 0;
    color: var(--c-primary);
  }

  &__button {
    margin-top: 1rem;
    padding: .75rem 1.25rem;
  }
}
