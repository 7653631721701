@import "../../styles/helpers";

.bottom-navigation-with-exit-button {
  display: flex;
  justify-content: flex-end;

  margin-bottom: 1.625rem;
  padding-top: 1.625rem;

  &__bottom-navigation {
    margin-right: 0.5rem;
    margin-bottom: 0;
    padding-top: 0;
  }

  &__save-and-exit-button {
    text-decoration: none;
  }
}
