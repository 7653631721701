@import "../../styles/helpers";

.bar-chart {
  max-width: 37.5rem;

  &__axis {
    font-size: .875rem;
    text-transform: capitalize;
    color: var(--c-gray-dark);
  }

  &__y-line {
    stroke: var(--c-gray);
    stroke-width: 1px;
  }

  &__bar {
    fill: var(--c-primary);

    &--1 { fill: #007ecc; }
    &--2 { fill: #009eff; }
    &--3 { fill: #33b1ff; }
    &--4 { fill: #66c5ff; }
    &--5 { fill: #99d8ff; }
  }
}
