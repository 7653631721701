@import "../../styles/helpers";

$component: 'likert-scale';

.#{$component} {

  &__header {
    display: flex;
    flex-direction: column;

    @include for-size(desktop-up) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  &__theme-title {
    margin: 0;
    margin-bottom: 1.25rem;

    @include for-size(desktop-up) {
      margin-bottom: 0;
    }
  }

  &__rating-container {
    display: flex;
    align-items: flex-end;
    width: 100%;
    max-width: 30rem;

    &--align-right {

      @include for-size(desktop-up) {
        margin-left: auto;
      }
    }
  }

  &__rating-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% / 5);
  }

  &__rating-text-label {
    margin: 0;
    font-weight: 400;
    text-align: center;

    & + & {
      margin-top: .625rem;
    }
  }

  &__form {
    margin-top: 1.25rem;
  }

  &__question-container {
    display: flex;
    flex-direction: column;
    padding-top: .625rem;
    padding-bottom: .625rem;

    &:focus-within {
      box-shadow: inset 0 0 0 2px var(--c-secondary);
    }

    &:nth-of-type(odd) {
      background-color: var(--c-gray-light);
    }

    @include for-size(desktop-up) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .#{$component}__rating-container {
      margin-top: .625rem;

      @include for-size(desktop-up) {
        margin-top: 0;
      }
    }
  }

  &__question {
    flex: 1;
    padding-left: .625rem;
    font-size: 1rem;
  }

  &__rating-input {
    position: absolute;
    opacity: 0;

    &:checked ~ .#{$component}__rating-input-label {

      .#{$component}__rating-indication-box::before {
        content: "";
      }
    }

    &:focus ~ .#{$component}__rating-input-label {

      .#{$component}__rating-indication-box {
        color: var(--c-secondary);
      }
    }

    &:checked:disabled ~ .#{$component}__rating-input-label {
      color: var(--c-gray-dark);
      cursor: not-allowed;
      border-color: var(--c-primary);

      .#{$component}__rating-indication-box {
        border-color: var(--c-primary);
      }
    }

    &:disabled ~ .#{$component}__rating-input-label {
      color: var(--c-gray);
      cursor: not-allowed;
      border-color: var(--c-gray);
      opacity: .7;

      .#{$component}__rating-indication-box {
        border-color: var(--c-gray);
      }
    }
  }

  &__rating-input-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2rem;
    cursor: pointer;
  }

  &__rating-indication-box {
    @extend %indication-box;
    margin: 0;
  }
}
