@import "../../styles/helpers";

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0;
  background-color: var(--c-primary);

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95vw;
    max-width: 75rem;
    height: 100%;

    @include for-size(tablet-landscape-up) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__info {
    width: 100%;
    max-width: 40rem;
    color: var(--c-white);
  }

  &__info-logo {
    height: 2.5rem;
    color: currentColor;
  }

  &__info-title {
    margin-top: 2.5rem;
    font-size: 1.375rem;
    text-transform: uppercase;
    color: currentColor;
  }

  &__info-text {
    margin-top: 1.25rem;
    color: currentColor;
  }

  &__info-privacy-statement {
    margin-top: 2rem;
    font-size: .875rem;
    font-style: italic;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  &__card {
    position: relative;
    margin: 2.5rem 0 5rem 2.5rem;
    padding: 5rem;
    border-radius: 4px;
    background-color: var(--c-white);

    @include for-size(desktop-up) {
      margin-left: 10rem;
    }
  }

  &__form {
    width: 25rem;
  }

  &__form-fieldset {
    margin: 0;
    padding: 0;
    border: none;
  }

  &__form-legend {
    margin-bottom: 2.5rem;
    padding: 0;
    font-family: $f-secondary;
    font-size: 1.375rem;
    color: var(--c-primary);
  }

  &__form-username {
    margin-bottom: 1.25rem;
  }

  &__form-username-title {
    margin: 0;
    font-family: $f-primary;
    font-size: 1rem;
    font-weight: 400;
    color: var(--c-black);
  }

  &__form-username-value {
    margin: 0;
    margin-top: .625rem;
  }

  &__form-input {
    width: 100%;
  }

  &__form-password-feedback {
    margin: 1.25rem 0;
  }

  &__form-password-feedback-list {
    margin: 0;
    margin-top: 1rem;
    padding: 0;
  }

  &__form-password-feedback-item {
    display: flex;
    font-size: .875rem;
    font-weight: 400;

    &--is-valid {
      color: var(--c-primary);
    }

    & + & {
      margin-top: .25rem;
    }
  }

  &__form-password-feedback-bullet {
    display: flex;
    align-items: center;
    width: .625rem;
    height: 1rem;
    margin-right: .625rem;
    font-size: 1rem;
    color: currentColor;
  }

  &__form-password-feedback-check {
    width: .625rem;
    height: 1rem;
    margin-right: .625rem;
    color: currentColor;
  }

  &__form-error {
    margin: 1.4rem 0 0;
    color: var(--c-negative);
  }

  &__form-submit, &__form-button-back {
    padding: .875rem 4.375rem;
    font-size: 1rem;
    color: var(--c-white);
    cursor: pointer;
    border: none;
    background-color: var(--c-primary);

    &:disabled {
      cursor: not-allowed;
      color: var(--c-gray);
      background-color: var(--c-gray-light);
    }
  }

  &__forgot-password {
    position: absolute;
    right: 0;
    bottom: -3.5rem;
    margin: 0;
    text-align: right;
    font-size: .875rem;
    font-style: italic;
    color: var(--c-white);

    a {
      margin: 0 0.25rem;
      color: currentColor;
      text-decoration: underline;
    }
  }

  &__form-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 2.5rem;
  }

  &__button-forgot-password {
    border: 0;
    font-style: inherit;
    font-size: inherit;
    text-decoration: underline;
    color: inherit;
    cursor: pointer;
    background: 0;

    &:hover {
      color: var(--c-primary-dark);
    }
  }

  &__button-back {
    margin-right: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: .875rem;
    color: var(--c-primary);
    background: none;

    &:focus, &:hover {
      color: var(--c-primary-dark);
      background: none;
    }
  }
}
