@import "../../../../styles/helpers";

.module-summary-item {
  line-height: 1.5;
  padding-left: 0.5rem;
  background-position: 0 0.25rem;
  background-size: 1rem;
  background-repeat: no-repeat;

  &--is-diagnose {
    background-image: url('/images/inzicht.svg');
  }

  &--is-theory {
    background-image: url('/images/onderzoek.svg');
  }

  &--is-practice {
    background-image: url('/images/vaardigheid.svg');
  }

  &--has-icon {
    padding-left: 2rem;
  }
}