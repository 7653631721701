@import "../../styles/helpers";

$component: justified-score-scale;

.#{$component} {
  &__text-and-scale-container {
    display: flex;
    flex-direction: column;

    @include for-size(tablet-landscape-up) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 1rem;

    @include for-size(tablet-landscape-up) {
      margin-right: 3rem;
      margin-bottom: 0;
    }
  }

  .#{$component}__title {
    margin: 0 0 1rem;
    color: var(--c-black);
  }

  &__scale-container {
    margin: 0;
    width: 100%;
    max-width: 20rem;
  }

  &__scale-legend {
    display: flex;
    justify-content: space-between;
  }

  &__buttons {
    display: flex;
    margin-bottom: 1rem;
  }

  &__textarea {
    min-height: 7rem;
  }
}
