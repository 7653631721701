@import "../../styles/helpers";

.module-summary {

  &__list {
    list-style: none;
    margin-bottom: 2rem;
    padding: 0;
  }
}
