@import "../../styles/helpers";

.module-status-card {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1.5rem;
  box-shadow: var(--box-shadow);
  background: var(--c-primary-light);

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    width: 100%;
  }

  &__title {
    font-size: 1.375rem;
    word-break: break-word;
  }

  &__duration-estimation {
    margin-left: 0.5rem;
    background: var(--c-white);
  }

  &__intro {
    margin: 0;
    font-size: 0.875rem;
  }

  &__progress-bar {
    margin-bottom: 2rem;
  }

  &__button {
    align-self: flex-end;
    margin-top: auto;
    text-decoration: none;
  }
}